import React from 'react';
import Link from '/src/components/LocalizedLink';
import Layout from '../components/Layout';
import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';

import '../assets/styles/pages/application-recieved-page.scss';

const AppcilactionRecieved = () => {
  return (
    <Layout title="Application Recieved">
      <div className="application-recieved-page">
        <section className="first-section">
          <div className="container">
            <div className="center">
              <h1 className="first-section__title">Application Received</h1>
              <p className="c-text-18 first-section__description">
                Many thanks for your application!
                <br />
                <br />
                Your application has been received and we will be reviewing it shortly. This
                typically takes under a day (Monday-Friday) and we’ll contact you soon to arrange a
                video / phone / in-person interview.
                <br />
                <br />
                We will be in touch via email,{' '}
                <strong>please check your spam folder if you do not hear from us!</strong>
                <br />
                <br />
                The ILA Team
              </p>
              <Link to="/" className="c-btn c-btn--red">
                Return To The Home Page
                <IconArrowRight />
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AppcilactionRecieved;
